import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from '../../../service/data.service';
import { LayoutService } from '../../../service/layout.service';
import { Company } from '../../../interface/company';
import { MigrateCommonService } from 'src/app/service/migrate-common.service';

interface DataType {
  model: 'NFCe' | 'NFe';
  linkNf: string;
  linkNFSe: string;
  backTo: string,
  codeSystem?: number,
  companyLabors?: Company,
  companyParts?: Company,
}

@Component({
  selector: 'app-ask-redirect',
  templateUrl: './ask-redirect.component.html',
  styleUrls: ['./ask-redirect.component.scss']
})
export class AskRedirectComponent implements OnInit {

  nfError;
  nfseError;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataType,
    public dataService: DataService,
    public layout: LayoutService,
    private _migrateCommonService: MigrateCommonService,
  ) { }

  ngOnInit() {
    this.getErrors()
  }

  private async getErrors() {
    const allNf = await this._migrateCommonService.getAll_NF(this.data.companyParts);
    const nf = allNf.find(invoice => invoice.id === this.data.linkNf);
    if (!nf) {
      this.nfError = "not_found";
    } else {
      const error = this._migrateCommonService.getErrors(nf, false)
      if (!error || (error.migrateCode && error.sefazCode && error.migrateCode === 100 && error.sefazCode === 100)) {
        this.nfError = null
      } else {
        this.nfError = error
      }
    }

    if (this.data.linkNFSe) {
      const allNfse = await this._migrateCommonService.getAll_NFSE(this.data.companyLabors);
      const nfse = allNfse.slice().concat(allNf as any).find(invoice => invoice.id === this.data.linkNFSe);
      if (!nfse) {
        this.nfseError = "not_found";
      } else {
        const error = this._migrateCommonService.getErrors(nfse, true);
        if (!error || (error.migrateCode && error.sefazCode && error.migrateCode === 100 && error.sefazCode === 100)) {
          this.nfseError = null
        } else {
          this.nfseError = error
        }
      }
    }
  }

}